const prod = true;

let api = "http://127.0.0.1:3333";

if(prod) {
    api = "https://api.paixoxo.com.br";
}

const envs = {
    api_url: api
}


export default envs