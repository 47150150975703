

const colors = {
    primary: {
        light: "#FFDCBF",
        medium: "#FD933C",
        dark: "#ff7300",
    },
    secondary: {
        light: "#B4D7FF",
        medium: "#65A9F7",
        dark: "#2660A4",
    },
    dark: {
        light: "#D3D3D3",
        medium: "#877E7E",
        dark: "#1E1C1C",
    },
}

export default colors