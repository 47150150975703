import { SLabel } from "./styles"


const Label = ({children}) => {
    return (
        <SLabel>
            {children}
        </SLabel>
    )
}

export default Label