import Button from "../Button"
import Container from "../Conteiner"
import TextArea from "../TextArea"
import { SAIChat } from "./styles"


const Message = ({data}) => {
    return (
        <div className={"message-" + data.role}>
            <div className="message">
                {data.text}
            </div>
        </div>
    )
}

const IAChat = ({messages, value, name, setValue, sendMessage}) => {
    return (
        <SAIChat>
            <div className="chat-container">
            {
                        !messages.length ?
                            <div className="chat-no-messages">
                                <span>Envie uma mensagem para começar uma conversa.</span>
                            </div>
                        : null
                    }
                <div className="chat-wrapper">
                
                    <div className="chat-messages">
                        {
                            messages.map( (m, k) => <Message key={"msg-" + k} data={m}/>)
                        }
                    </div>
                </div>
            </div>
            <div className="chat-textbox">
                <div>
                    <TextArea value={value} name={name} setValue={setValue} placeholder="Digite sua mensagem..."/>
                </div>
                <div className="mt-3">
                    <Button onClick={sendMessage}>
                        Enviar
                    </Button>
                </div>
            </div>
        </SAIChat>
    )
}

export default IAChat