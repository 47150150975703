import WhatsappQR from "../../../components/WhatsappQR"
import { SHome } from "./styles"

const PageDashboard = () => {
    return (
        <SHome>
            <div>
               
            </div>
        </SHome>
    )
}


export default PageDashboard