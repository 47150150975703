import PageDashboard from "./Dashboard/Home"
import PageDashboardConfig from "./Dashboard/Config"
import PageLogin from "./Login"
import IAPage from "./Dashboard/IA"


const pages = {
    PageLogin,
    Dashboard: {
        Home:   PageDashboard,
        IA: IAPage,
        Config: PageDashboardConfig,
    }
}


export default pages