import { useNavigate } from "react-router-dom"
import { useState } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Row from "../../components/Row";
import Title from "../../components/Title";
import { SLogin } from "./styles";
import api from "../../services/api";


const PageLogin = () => {

    const navigate = useNavigate();

    const [form, setForm] = useState({
        loading: false,
        username: "",
        password: "",
    })

    const setValue = (name, value) => {
        setForm({...form, [name]: value})
    }

    const signin = async () => {
        setForm({...form, loading: true})
        try {
            const res = await api({
                method: "post",
                action: "signin",
                data: {
                    username: form.username,
                    password: form.password
                }
            })

            if(res.data.accessToken) {
                localStorage.setItem("TERCES", res.data.accessToken);
                navigate("/dashboard/home");
            }
        } catch (error) {
            console.log('====================================');
            console.log("signin:", error);
            console.log('====================================');
        }
        setForm({...form, loading: false})
    }

    return (
        <SLogin>
            <div id="login-form">
                <div id="login-form-content">
                    <Row className="mb-5">
                        <Title className="text-center">Login</Title>
                    </Row>
                    <Row  className="mb-4">
                        <Input value={form.username} name={"username"} setValue={setValue} placeholder="Usuário"/>
                    </Row>
                    <Row  className="mb-5">
                        <Input value={form.password} name={"password"} setValue={setValue} placeholder="Senha"/>
                    </Row>
                    <Row className="d-flex justify-center mb-4">
                        <Button onClick={() => signin()} size="full">{form.loading ? "Carregando..." : "Logar"}</Button>
                    </Row>
                </div>
            </div>
        </SLogin>
    )
}


export default PageLogin;