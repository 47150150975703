import { SLogo } from "./styles";



const Logo = () => {
    return (
        <SLogo>
            <h1>PaiXOXO</h1>
        </SLogo>
    )
}


export default Logo;