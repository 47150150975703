import { useEffect } from "react";
import Header from "../../components/Header";
import Logo from "../../components/Logo";
import Menu from "../../components/Menu";
import { SDashboardLayout } from "./styles";
import { Outlet } from "react-router-dom";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";

const DashboardLayout = () => {
    const navigate = useNavigate();

    const valid = async () => {
        const res = await api({
            method: 'get',
            action: "valid",
            auth: true
        });
        if(!res.data) {
            navigate("/");
        }
    }

    useEffect(() => {
        valid()
    },[])

    return (
        <SDashboardLayout>
            <div className="main">
                <div className="left-side">
                    <Logo/>
                    <Menu/>
                </div>
                <div className="right-side" >
                    <Header/>
                    <div className="content">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </SDashboardLayout>
    )
}


export default DashboardLayout