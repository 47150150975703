import axios from "axios";
import API_PATHS from "./api_paths";
import envs from "./envs";

const api = async ({method, action, data = {}, params = {}, auth = false, headers = {}}) => {
    let path = API_PATHS[action];
    if(params) {
        Object.keys(params).forEach( key => {
            path = path.replace(`:${key}`, params[key])
        })
    };

    let res;
    let error;
    let API_URL = envs.api_url;

    const TERCES = localStorage.getItem("TERCES");
    
    method = method.toLowerCase();

    try {
        if(method === 'post') {
            res = await axios.post(API_URL + path, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    ...headers,
                    ...(auth ? {"x-access-token": `${TERCES}`} : {}),
                }
            })
        }
        if(method === 'get') {
            res = await axios.get(API_URL + path, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    ...headers,
                    ...(auth ? {"x-access-token": `${TERCES}`} : {}),
                }
            })
        }
        if(method === 'put') {
            res = await axios.put(API_URL + path, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    ...headers,
                    ...(auth ? {"x-access-token": `${TERCES}`} : {}),
                }
            })
        }
        if(method === 'delete') {
           res = await axios.delete(API_URL + path, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8',
                    ...headers,
                    ...(auth ? {"x-access-token": `${TERCES}`} : {}),
                }
            })
        }

        // console.log(`succes - ${method} ${path}:`,res.data);
    } catch (err) {
        res = err;
        console.log(`error - ${method} ${path}:`,err);
    }
    return res;
}

export default api