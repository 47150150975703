import { SRow } from "./styles"

const Row = ({children, ...props}) => {
    return (
        <SRow {...props}>
            {children}
        </SRow>
    )
}


export default Row