import styled from '@emotion/styled';
import colors from '../../theme/colors';

export const SLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    #login-form {
        width: 360px;
        border: 1px solid ${colors.primary.dark};
        border-radius: 4px;
        padding: 12px;
    }
`;