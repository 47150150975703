import styled from '@emotion/styled';
import colors from '../../theme/colors';

export const SAIChat = styled.div`
 
    .chat-container {
        position: relative;
        height: 60vh;
        .chat-no-messages {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: ${colors.primary.dark};
            }
        }
        .chat-wrapper {
      
            height: 100%;
            padding: 12px;
            .chat-messages {
                position: absolute;
                left: 0;
                bottom: 0;
                max-height: 100%;
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 12px;

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-track {
                    background: ${colors.primary.light}; 
                }
                
                &::-webkit-scrollbar-thumb {
                    background:  ${colors.primary.dark};
                }
                .message {
                    margin-bottom: 12px;
                }

                .message-user {
                    width: 100%;
                    display: flex;
                    justify-content: right;
                    .message {
                        background-color: ${colors.primary.medium};
                        padding: 12px;
                        border-radius: 12px 0 12px 12px;
                        max-width: 50%;
                        position: relative;
                        color: #fff;
                        :before {
                            content: ' ';
                            position: absolute;
                            width: 0;
                            height: 0;
                            left: auto;
                            right: -6px;
                            top: 0px;
                            border: 6px solid;
                            border-color: ${colors.primary.medium} transparent transparent transparent;
                        }
                    }
                }

                .message-model {
                    width: 100%;
                    display: flex;
                    justify-content: left;
                    .message {
                        background-color: ${colors.secondary.medium};
                        padding: 12px;
                        border-radius: 0 12px 12px 12px;
                        max-width: 50%;
                        position: relative;
                        color: #fff;
                        :before {
                            content: ' ';
                            position: absolute;
                            width: 0;
                            height: 0;
                            left: auto;
                            left: -6px;
                            top: 0px;
                            border: 6px solid;
                            border-color: ${colors.secondary.medium} transparent transparent transparent;
                        }
                    }
                }
            }
            
        }
        .chat-textbox {
            padding-top: 24px;
            height: 240px;
        }
    }
`;