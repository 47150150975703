import { SContainer } from "./styles"


const Container = ({children, ...props}) => {
    return (
        <SContainer {...props}>
            {children}
        </SContainer>
    )
}


export default Container