import styled from '@emotion/styled';
import colors from '../../theme/colors';

export const SMenu = styled.div`
    width: 240px;
    height: 100vh;
    padding: 1px;
`;

export const SMenuItem = styled.div`
    margin-top: 24px;
    border-radius: 0px 20px 20px 0px;
    transition: 0.125s ease-in;
    .primary-item {
        padding: 10px 35px 10px 35px;
    }
    cursor: pointer;
    i {
        margin-right: 12px;
    }
    ${
        p => p.current ? `background-color: #fff;` : ''
    }
    * {
        color: ${ p => p.current ? colors.primary.dark : colors.dark.dark};
        font-size: 14px;
    }
    .subs {
        .sub-active {
            span, i {
                color: ${colors.primary.dark};
            }
        }
    }
    .sub-menu {
        margin-top: 12px;
        margin-left: 24px;
        padding: 10px 35px 10px 35px;
        display: ${ p => p.current ? "flex" : 'none'};
   
        span, i {
            :hover {
                color: ${ p => p.current ? colors.primary.dark : colors.dark.dark};
            }
        }
        span, i {
            color: ${colors.dark.medium};
            font-size: 12px;
        }
      
        i {
            font-size: 6px;
            margin-right: 12px;
        }
    }
`;