import styled from '@emotion/styled';

export const SCheckbox = styled.label`

    .checkbox-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .checkbox-label {
        margin-left: 12px;
        font-size: 12px;
    }

    .checkbox-switch-container {
        position: relative;
        width: 48px;
    }

    .input-checkbox {
        opacity: 0;
    }
    
    .switch {
        position: relative;
        display: inline-block;
        width: 48px;
        height: 24px;
    }
    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        border-radius: 12px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 12px;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: none;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 12px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;