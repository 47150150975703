import styled from '@emotion/styled';

export const SDashboardLayout = styled.div`
    display: flex;
    justify-content: center;
    .main {
        display: flex;
        justify-content: center;
        width: 1024px;
    }
    .content {
        padding-top: 24px;
    }
    .right-side {
        width: calc(100% - 240px);
        padding: 1.5rem 2.187rem 1.5rem 3.5rem;
    }
`;