import { SCheckbox } from "./styles"


const Checkbox = ({label, name, value, setValue}) => {
    return (
        <SCheckbox>
            <div className="checkbox-container">
                <div className="checkbox-switch-container">
                    <input checked={value} className="input-checkbox" type="checkbox" onChange={() => setValue(name, !value)}/>
                    <span className="slider"></span>
                </div>
                <span className="checkbox-label">{label}</span>
            </div>
        </SCheckbox>
    )
}

export default Checkbox