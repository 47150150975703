import colors from "../../theme/colors"
import { SButton } from "./styles"

const getColors = (color) => {
    if(color==="primary") {
        return {
            bg: colors.primary.medium,
            bg_hover: colors.primary.dark,
            text: "#fff"
        }
    }

    if(color==="secondary") {
        return {
            bg: colors.secondary.medium,
            bg_hover: colors.secondary.dark,
            text: "#fff"
        }
    }

    if(color==="dark") {
        return {
            bg: colors.dark.medium,
            bg_hover: colors.dark.dark,
            text: "#fff"
        }
    }
}

const Button = ({children, color = "primary", size = "auto", onClick, ...props}) => {
    
    const _color = getColors(color) 

    return (
        <SButton {...props} $hover={_color.bg_hover} $color={_color.bg} type="button" $size={size} onClick={onClick} style={{color: _color.text}}>
            {children}
        </SButton>
    )
}


export default Button