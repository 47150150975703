import styled from '@emotion/styled';




export const SButton = styled.button`
    width: ${ p => p.$size === "full" ? "100%" : "auto" };
    font-size: 18px;
    outline: none;
    border: none;
    padding: 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color:${ p => p.$color};
    transition: 0.2s ease-in;
    &:hover {
        background-color: ${ p => p.$hover};
    }
`;