import styled from "@emotion/styled"

export const SWhatsappQR = styled.div`

`;

export const QRBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 15vh;
`;

export const QrDesc = styled.section`
    width: 100%;
    text-align: center;
`;

export const QRCodeImageBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;


export const ShareQrBlock = styled.div`
    font-size: 24px;
    span {
        cursor: pointer;
    }
`;

export const ShareQrCode = styled.div`
    display: inline-block;
    background-color: #111;
    border-radius: 5px;
    color: #fff;
    padding: 4px 8px;
    font-size: 24px;
    span {
        cursor: pointer;
    }
`;
export const NewCode = styled.div`
    cursor: pointer;
    display: inline-block;
    background-color: #c91b1b;
    border-radius: 5px;
    color: #fff;
    padding: 4px 8px;
    font-size: 24px;
    span {
        cursor: pointer;
    }
`;

export const QrCodeCopy = styled.span`
    color: #111;
    font-size: 27px;
`;

export const QRCodeImage = styled.img`
    margin-top: 48px;
    width: 300px;
    height: 300px;
`;

export const QrCodeRestart = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    h5 {
        width: 100%;
        text-align: center;
    }
    svg {
        cursor: pointer;
        font-size: 128px;
    }
`;