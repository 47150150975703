import styled from '@emotion/styled'
import colors from '../../theme/colors';

export const SInput = styled.input`
    padding: 12px;
    width: 100%;
    border: solid 1px ${colors.dark.light};
    outline: none;
    transition: .2s ease-in;
    border-radius: 4px;
    box-sizing: border-box;
    &:focus {
        border: solid 1px ${colors.primary.medium};
    }
`;
