import { STitle } from "./styles"




const Title = ({size = "h3", color="primary", children, ...props}) => {
    return (
        <STitle {...props}>
            {children}
        </STitle>
    )
}

export default Title