import { useState } from "react"
import TextArea from "../../../components/TextArea"
import { StyledAI } from "./styles"
import IAChat from "../../../components/IAChat";
import Container from "../../../components/Conteiner";
import Button from "../../../components/Button";
import api from "../../../services/api";
import { useEffect } from "react";


global.messages = [];

const IAPage = () => {
    const [prompt, setPrompt] = useState({
        queried: false,
        data: ""
    });

    const [listening, setListening] = useState(0)

    const [chat, setChat] = useState({
        queried: false,
        input: ""
    })

    const [messages, setMessages] = useState({
        queried: false,
        data: []
    })

    const setInput = (name, value) => {
        setChat({
            ...chat,
            [name]: value
        })
    }

    const getPrompt = async () => {
        const res = await api({
            method: 'get',
            action: "getConfig",
            auth: true
        });
        if(res.data) {
            setPrompt({queried: true, data: res.data.prompt})
        }
    }

    const getMessages = async () => {
        const res = await api({
            method: 'get',
            action: "iaGetMessages",
            auth: true
        });
        if(res.data) {
            console.log("global.messages:",global.messages);
            global.messages = [...global.messages, ...res.data]
            setMessages({queried: true, data: global.messages})
        }
        setListening((new Date).getTime())

    }

    const updatePrompt  = async () => {
        const res = await api({
            method: 'post',
            action: "iaPutPrompt",
            data: {
                prompt: prompt.data
            },
            auth: true
        });
        global.messages = [];
        setMessages({queried: true, data: []})
    }

    const sendMessage = async () => {
        if(chat.input) {
            const text = chat.input;
            global.messages = [...global.messages, {
                text,
                role: "user"
            }]
            
            setChat({...chat,
                input: ""
            })


            const res = await api({
                method: 'post',
                action: "iaSendMessage",
                data: {
                    text
                },
                auth: true
            });
            
        }
    }

    const handleForm = (name, value) => {
        setPrompt({...prompt, data: value})
    }

    const scrollToBottom = () => {
        const e = document.getElementsByClassName("chat-messages");
        if(e && e[0]) {
            e[0].scrollTop = e[0].scrollHeight;
        }
    }

    useEffect(() => {
        if(!prompt.queried)  {
            getPrompt()
        }
    },[prompt])

    useEffect(() => {
      
        if(!listening)  {
            getMessages()
        } else {
            setTimeout( () => {
                getMessages()
            }, 2000)
        }

        scrollToBottom()

    },[listening])

    return (
        <StyledAI>
            <div className="prompt-editor mb-5">
                <h6 className="mb-2">Comandos (Prompts)</h6>
                <Container>
                    <TextArea value={prompt.data} setValue={handleForm} name={"prompt"} placeholder="Comandos..."/>
                    <div className="mt-3">
                        <Button onClick={ () => updatePrompt()}>
                            Salvar
                        </Button>
                    </div>
                </Container>
            </div>
            <div className="ia-chat">
                <h6 className="mb-2">Chat</h6>
                <Container>
                    <IAChat messages={messages.data} name="input" value={chat.input} setValue={setInput} sendMessage={() => sendMessage()}/>
                </Container>
            </div>
        </StyledAI>
    )
}

export default IAPage