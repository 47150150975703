import { SHeader } from "./styles";

const Header = () => {
    return (
        <SHeader>
            
        </SHeader>
    )
}

export default Header;