import { useState } from "react"
import { SPageDashboardConfig } from "./styles"
import Button from "../../../components/Button";
import { useEffect } from "react";
import api from "../../../services/api";
import {useParams} from "react-router-dom";
import Container from "../../../components/Conteiner";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import colors from "../../../theme/colors";
import Checkbox from "../../../components/Checkbox";


const PageDashboardConfig = () => {
    const [form, setForm] = useState({
        queried: false,
        dev_number: "",
        ia_dev_mode: false,
        payment_dev_mode: false,
        secret_dev_key: "",
        timesleep: "",
        public_dev_key: "",
        public_prod_key: "",
        secret_prod_key: "",
        questions_size_per_purchase: "",
        price: "",
    });

    const {id} = useParams();

    const setValue = (name, value) => {
        let _value = value
        setForm({...form, [name]: _value})
    }

    const getConfig = async () => {
        const res = await api({
            method: 'get',
            action: "getConfig",
            auth: true
        });
        if(res.data) {
            setForm({...form, queried: true, ...res.data})
        }
    }

    const updateConfig  = async () => {
        const res = await api({
            method: 'post',
            action: "updateConfig",
            data: {
                _id: form._id,
                dev_number: form.dev_number,
                ia_dev_mode: form.ia_dev_mode,
                timesleep: form.timesleep,
                payment_dev_mode: form.payment_dev_mode,
                secret_dev_key: form.secret_dev_key,
                public_dev_key: form.public_dev_key,
                public_prod_key: form.public_prod_key,
                secret_prod_key: form.secret_prod_key,
                questions_size_per_purchase: form.questions_size_per_purchase,
                price: form.price
            },
            auth: true
        });
        if(res.data) {
            setForm({...form, queried: true, ...res.data})
        }
    }

    useEffect(() => {
        if(!form.queried) getConfig()
    },[form]);

    return (
        <SPageDashboardConfig>
            <Container>
                <div className="pb-5 mb-5">
                    <div className="mb-4">
                        <h4>IA</h4>
                    </div>
                    <div className="mt-3">
                        <Checkbox value={form.ia_dev_mode} name={"ia_dev_mode"} setValue={setValue} label={"IA em modo desenvolvimento"}/>
                    </div>
                    <div className="mt-3">
                        <Label>Número DEV:</Label>
                        <Input value={form.dev_number} name="dev_number" setValue={setValue} placeholder="21999999999"/>
                        <small style={{color: colors.dark.medium, fontSize: 12 }}>Número usado em testes de desenvolvimento</small>
                    </div>
                    <div className="mt-3">
                        <Input value={form.timesleep} name="timesleep" setValue={setValue} placeholder="0"/>
                        <small style={{color: colors.dark.medium, fontSize: 12 }}>Tempo de espera para juntar as mensagens</small>
                    </div>
                </div>
                
                <div className="pb-5 mb-5">
                    <div className="mb-4">
                        <h4>Pagamentos</h4>
                    </div>
                    <div className="mt-3">
                        <Checkbox value={form.payment_dev_mode} setValue={setValue} name="payment_dev_mode" label={"Pagamentos em modo desenvolvimento"}/>
                    </div>
                    <div className="mt-5 mb-3">
                        <h6>PRECIFICAÇÃO</h6>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <Label>Valor:</Label>
                            <Input value={form.price} type="number" name="price" setValue={setValue} placeholder="Exemplo: 29,99"/>
                        </div>
                        <div className="col-6">
                            <Label>Quantidade de perguntas:</Label>
                            <Input  value={form.questions_size_per_purchase} type="number" name="questions_size_per_purchase" setValue={setValue} placeholder="Exemplo: 10"/>
                        </div>
                    </div>
                    <div className="mt-5 mb-3">
                        <h6>CHAVES DE PRODUÇÃO</h6>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <Label>Chave Secreta (produção):</Label>
                            <Input value={form.secret_prod_key} name="secret_prod_key" setValue={setValue} placeholder="sk_*"/>
                        </div>
                        <div className="col-6">
                            <Label>Chave Publica (produção):</Label>
                            <Input value={form.public_prod_key} name="public_prod_key" setValue={setValue} placeholder="pk_*"/>
                        </div>
                    </div>
                    <div className="mt-5 mb-3">
                        <h6>CHAVES DE DESENVOLVIMENTO</h6>
                    </div>
                    <div className="row mt-3">
          
                        <div className="col-6">
                            <Label>Chave Secreta (sandbox):</Label>
                            <Input value={form.secret_dev_key} name="secret_dev_key" setValue={setValue} placeholder="sk_test_*"/>
                        </div>
                        <div className="col-6">
                            <Label>Chave Publica (sandbox):</Label>
                            <Input value={form.public_dev_key} name="public_dev_key" setValue={setValue} placeholder="pk_test_*"/>
                        </div>
                    </div>
                </div>



                <div className="mt-5">
                    <Button className="px-5" onClick={updateConfig}>Salvar</Button>
                </div>
            </Container>
        </SPageDashboardConfig>
    )
}


export default PageDashboardConfig